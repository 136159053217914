<template>
    <footer id="footer">
    <div class="container">
      
      <div class="row">
      <div class="col-lg-6 col-md-12 text-center">
        <ul class="footermenu">
          <li class="footerborder"><a href="../#about">About us</a></li>
          <li class="footerborder"> <a href="../#services">Services</a></li>
          <li class="footerborders"> <a :href="url_privacy">Privacy policy</a></li>
        </ul>
    </div>
      <div class="col-lg-6 col-md-12 socialfooter">
        <div class="social-links">
          <a href="mailto:legal@vezham.com" target="_blank"> <ICON_mail :size="icon_size"/> </a>
          <a href="https://twitter.com/vezham_vtpl" target="blank" class="twitter"> <ICON_twitter :size="icon_size"/> </a>
          <a href="https://instagram.com/vezham_vtpl" target="blank" class="instagram"> <ICON_instagram :size="icon_size"/> </a>
       <!-- <a href="#" target="blank"> <ICON_telegram :size="icon_size"/></a> -->
        </div>
      
    </div>
  </div>
  <div class="row">
    <div class="col-lg-2 col-md-1 text-center"></div>
    <div class="col-lg-8 col-md-12 text-center">
      <div class="copyright">
        Copyrights &copy; 2022, All Rights Reserved by Vezham Technologies Private Limited
      </div>
     </div>
  <div class="col-lg-2 col-md-1 text-center"></div>
</div>
    </div>
  </footer><!-- #footer -->
     
    <!--/ End Footer Area -->
     <a href="#" class="scroll-top">
        <i class="lni lni-chevron-up"></i>
    </a>
</template>

<script>
import { whatsapp_api, url_privacy } from '@/constants/constant.js'
import ICON_twitter from '@/components/icons/twitter.vue'
import ICON_instagram from '@/components/icons/instagram.vue'
import ICON_mail from '@/components/icons/mail.vue'

export default {
   components: {
    ICON_twitter, ICON_instagram, ICON_mail
  },
  data() {
      return { 
        icon_size: 24,
        whatsapp_api, url_privacy
      }
  }
}
</script>
<style scoped>
@media (max-width: 992px) {
.socialfooter {
    text-align: center !important;
    padding: 10px 0px !important;
}
}

.socialfooter {
    text-align: right;
    padding: 0px 30px;
}
ul.social li {
  display: inline-block;
  margin: 8px;
}
ul.social li  a{
  font-size: 16px;
  color: #eee;
  line-height: 20px;
  letter-spacing: 1px;
  list-style-type: none;
}
.social-links a {
  display: inline-block;
  color: #eee;
  padding: 8px;
  margin-right: 10px;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}
.social-links a:hover {
  color: #fff;
}
.social-links {
  padding-top: 15px;
}
@media (max-width: 991px) {
.social-links {
  padding: 0px 20px 20px 20px;
}
.scroll-top {
    width: 50px !important;
    height: 50px !important;
    line-height: 50px !important;
    font-size: 30px !important;
     bottom: 115px !important;
}
}
/* One Click Scrool Top Button*/
.scroll-top {
  width: 45px;
  text-decoration: none;
  height: 45px;
  line-height: 45px;
  background: #0880e8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  color: #fff !important;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 98;
  cursor: pointer;
  -webkit-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
  border-radius: 5px;
}

.scroll-top:hover {
  -webkit-box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  -webkit-transform: translate3d(0, -5px, 0);
  transform: translate3d(0, -5px, 0);
  background-color: #000000;
}
/*======================================
	Footer CSS
========================================*/
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

#footer {
  background: #101522;
  padding: 0 0 25px 0;
  color: #eee;
  font-size: 14px;
}

#footer .footer-top {
  background: #040919;
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 26px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  color: #fff;
}

#footer .footer-top  {
  height: 40px;
  margin-bottom: 10px;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
  color: #fff;
}


#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 5px;
  color: #f82249;
  font-size: 18px;
}

#footer .footer-top .footer-links ul li {
  border-bottom: 1px solid #262c44;
  padding: 10px 0;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #eee;
}

#footer .footer-top .footer-links ul a:hover {
  color: #fff;
}

#footer .copyright {
  text-align: center;
  font-size: 12px;
}

#footer .credits {
  text-align: center;
  font-size: 13px;
  color: #ddd;
}
@media (max-width: 1199px) {
  #header .container {
    max-width: 100%;
  }

  .nav-menu a { 
    padding: 8px 4px;
  }
  .footermenu> li {
    padding: 5px !important;
  }
}

@media (max-width: 991px) {
  #footer {
    padding: 40px 0 60px 0;
    font-size: 35px !important;
}
li.footerborder {
    font-size: 16px;
    text-decoration: none;
    list-style: none
}
li.footerborders {
    font-size: 16px;
    text-decoration: none;
    list-style: none
}
#footer .copyright {
    font-size: 16px !important;
    margin-bottom: 80px;
}
ul.social li a {
    font-size: 30px !important;
    line-height: 40px !important;
}
ul.social li {
    margin: 30px !important;
}
.social-links a {
    width: 35px !important;
    height: 35px !important;
    padding: 8px 6px !important;
    margin-right: 10px;
}
li.footerborder > a:hover {
    font-size: 16px !important;
}
ul.footermenu{
  display: block !important;
  text-align: center;
  padding: 0px;
}
}
 @media only screen and (min-width: 800px){

ul.footermenu {
    display: flex;
    padding: 30px 0px 0px 20px;
}
.footermenu> li {
  padding: 0 10px;
  margin-bottom: 10px;
    text-decoration: none;
    list-style: none;
}
 }
li.footerborder {
  border-right: 1px solid #ccc;
}
li.footerborder > a {
  color: #fff;
      text-decoration: none;

}
li.footerborders > a {
  color: #fff;
      text-decoration: none;

}
li.footerborder > a:hover {
  color: #fff;
}
li.footerborders > a:hover {
  color: #fff;
}
@media only screen and (max-width: 991px) and (min-width: 0px) {
li.footerborder{
  border:none
}
}
</style>