<template>

<svg width="650" height="277" viewBox="0 0 650 277" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M189.511 182.654H165.632V112.445C165.632 110.598 165.268 108.77 164.56 107.064C163.852 105.358 162.815 103.808 161.507 102.502C160.2 101.196 158.647 100.161 156.939 99.4541C155.23 98.7475 153.399 98.3838 151.55 98.3838H145.954C142.219 98.3838 138.637 99.8652 135.996 102.502C133.355 105.139 131.872 108.715 131.872 112.445V182.654H64.5162C63.1305 182.654 61.7684 182.297 60.562 181.616C59.3555 180.936 58.3457 179.955 57.6305 178.77C56.9153 177.585 56.5189 176.236 56.48 174.853C56.441 173.47 56.7606 172.101 57.4079 170.877L128.696 36.1603C129.585 34.4807 130.124 32.6387 130.28 30.7455C130.436 28.8523 130.206 26.9472 129.604 25.1451C129.003 23.343 128.042 21.6813 126.779 20.2603C125.516 18.8393 123.978 17.6885 122.258 16.8774L118.05 14.8932C114.782 13.3525 111.046 13.125 107.615 14.2578C104.184 15.3906 101.321 17.7969 99.617 20.9789L12.6717 183.375C11.1911 186.14 10.4165 189.228 10.4165 192.363V192.363C10.4165 197.42 12.4284 202.269 16.0095 205.845C19.5906 209.421 24.4477 211.429 29.5122 211.429H131.872V255.825C131.872 258.039 132.308 260.23 133.157 262.275C134.005 264.32 135.248 266.178 136.816 267.743C138.383 269.308 140.244 270.55 142.292 271.397C144.34 272.244 146.535 272.679 148.752 272.679H148.752C153.229 272.679 157.522 270.904 160.688 267.743C163.854 264.582 165.632 260.295 165.632 255.825V211.429H189.511C191.404 211.429 193.277 211.057 195.026 210.334C196.774 209.611 198.363 208.551 199.701 207.215C201.039 205.879 202.1 204.293 202.824 202.548C203.548 200.802 203.921 198.931 203.921 197.042V197.042C203.921 195.152 203.548 193.281 202.824 191.536C202.1 189.79 201.039 188.204 199.701 186.868C198.363 185.532 196.774 184.472 195.026 183.749C193.277 183.026 191.404 182.654 189.511 182.654V182.654Z" stroke="url(#paint0_linear_736_3199)" stroke-miterlimit="10"/>
<path d="M632.454 181.654H608.575V111.445C608.575 107.715 607.091 104.139 604.45 101.502C601.809 98.8652 598.227 97.3838 594.492 97.3838H588.897C587.048 97.3838 585.216 97.7475 583.508 98.4541C581.799 99.1608 580.247 100.196 578.939 101.502C577.631 102.808 576.594 104.358 575.886 106.064C575.179 107.77 574.815 109.598 574.815 111.445V181.654H507.459C506.073 181.654 504.711 181.297 503.505 180.616C502.298 179.936 501.289 178.955 500.573 177.77C499.858 176.585 499.462 175.236 499.423 173.853C499.384 172.47 499.704 171.101 500.351 169.877L571.639 35.1603C572.528 33.4807 573.067 31.6387 573.223 29.7455C573.379 27.8523 573.149 25.9472 572.547 24.1451C571.946 22.343 570.985 20.6813 569.722 19.2603C568.459 17.8393 566.921 16.6885 565.201 15.8774L560.993 13.8932C557.725 12.3525 553.989 12.125 550.558 13.2578C547.127 14.3906 544.264 16.7969 542.56 19.9789L455.615 182.375C454.134 185.14 453.359 188.228 453.359 191.363C453.359 196.42 455.371 201.269 458.952 204.845C462.534 208.421 467.391 210.429 472.455 210.429H574.815V254.825C574.815 259.295 576.593 263.582 579.759 266.743C582.924 269.904 587.218 271.679 591.695 271.679V271.679C596.172 271.679 600.465 269.904 603.631 266.743C606.796 263.582 608.575 259.295 608.575 254.825V210.429H632.454C634.347 210.429 636.22 210.057 637.969 209.334C639.717 208.611 641.305 207.551 642.644 206.215C643.982 204.879 645.043 203.293 645.767 201.548C646.491 199.802 646.864 197.931 646.864 196.042V196.042C646.864 192.226 645.346 188.566 642.644 185.868C639.941 183.17 636.276 181.654 632.454 181.654V181.654Z" stroke="url(#paint1_linear_736_3199)" stroke-miterlimit="10"/>
<path d="M326.905 0.65918C269.15 0.65918 227.683 37.6559 227.683 123.982C227.683 221.817 269.15 247.715 326.905 247.715C384.66 247.715 428.598 219.351 428.598 123.982C428.598 22.0351 384.66 0.65918 326.905 0.65918ZM327.285 215.24C287.282 215.24 266.384 191.808 266.384 124.045C266.384 64.2536 288.138 32.723 328.14 32.723C368.143 32.723 389.897 53.4343 389.897 124.045C389.897 190.1 367.287 215.24 327.285 215.24V215.24Z" stroke="url(#paint2_linear_736_3199)" stroke-miterlimit="10"/>
<path d="M313.73 8.05762C255.975 8.05762 214.507 45.0544 214.507 131.38C214.507 229.216 255.975 255.114 313.73 255.114C371.485 255.114 415.423 226.749 415.423 131.38C415.423 29.4335 371.485 8.05762 313.73 8.05762ZM314.11 222.639C274.107 222.639 253.208 199.207 253.208 131.443C253.208 71.6521 274.963 40.1215 314.965 40.1215C354.968 40.1215 376.722 60.8327 376.722 131.443C376.722 197.498 354.112 222.639 314.11 222.639Z" fill="url(#paint3_linear_736_3199)"/>
<path d="M179.924 186.876H156.045V116.666C156.045 112.937 154.561 109.361 151.92 106.724C149.279 104.087 145.697 102.606 141.962 102.606H136.367C134.517 102.606 132.686 102.969 130.978 103.676C129.269 104.382 127.717 105.418 126.409 106.724C125.101 108.029 124.064 109.579 123.356 111.285C122.648 112.991 122.284 114.82 122.284 116.666V186.876H54.9288C53.5431 186.876 52.181 186.518 50.9746 185.838C49.7681 185.157 48.7583 184.177 48.0431 182.992C47.3279 181.807 46.9315 180.458 46.8925 179.075C46.8536 177.692 47.1732 176.322 47.8205 175.099L119.109 40.3819C119.998 38.7024 120.536 36.8604 120.692 34.9672C120.848 33.074 120.619 31.1688 120.017 29.3667C119.415 27.5646 118.454 25.903 117.192 24.482C115.929 23.061 114.391 21.9102 112.671 21.0991L108.462 19.1149C105.195 17.5742 101.459 17.3467 98.0278 18.4795C94.5968 19.6123 91.7332 22.0186 90.0296 25.2006L3.08425 187.597C1.60369 190.362 0.829101 193.449 0.829102 196.585V196.585C0.829102 199.089 1.32302 201.568 2.28267 203.881C3.24232 206.194 4.6489 208.296 6.42209 210.067C8.19529 211.837 10.3004 213.242 12.6172 214.2C14.934 215.158 17.4171 215.651 19.9248 215.651H122.284V260.047C122.284 262.26 122.721 264.452 123.569 266.497C124.417 268.542 125.661 270.4 127.228 271.965C128.796 273.53 130.657 274.771 132.705 275.618C134.753 276.465 136.948 276.901 139.164 276.901H139.164C141.381 276.901 143.576 276.465 145.624 275.618C147.672 274.771 149.533 273.53 151.1 271.965C152.668 270.4 153.911 268.542 154.76 266.497C155.608 264.452 156.045 262.26 156.045 260.047V215.651H179.924C183.746 215.651 187.411 214.135 190.113 211.437C192.816 208.739 194.334 205.079 194.334 201.263V201.263C194.334 197.448 192.816 193.788 190.113 191.09C187.411 188.392 183.746 186.876 179.924 186.876V186.876Z" fill="url(#paint4_linear_736_3199)"/>
<path d="M622.103 186.876H598.223V116.666C598.223 112.937 596.74 109.361 594.099 106.724C591.458 104.087 587.876 102.606 584.141 102.606H578.545C576.696 102.606 574.865 102.969 573.156 103.676C571.448 104.382 569.895 105.418 568.588 106.724C567.28 108.029 566.243 109.579 565.535 111.285C564.827 112.991 564.463 114.82 564.463 116.666V186.876H497.107C495.722 186.876 494.36 186.518 493.153 185.838C491.947 185.157 490.937 184.177 490.222 182.992C489.507 181.807 489.11 180.458 489.071 179.075C489.032 177.692 489.352 176.322 489.999 175.099L561.287 40.382C562.176 38.7024 562.715 36.8604 562.871 34.9672C563.027 33.074 562.797 31.1689 562.196 29.3667C561.594 27.5646 560.633 25.903 559.37 24.482C558.108 23.061 556.57 21.9102 554.849 21.0991L550.641 19.1149C547.373 17.5742 543.637 17.3467 540.206 18.4795C536.775 19.6123 533.912 22.0186 532.208 25.2006L445.263 187.597C443.782 190.362 443.008 193.449 443.008 196.585V196.585C443.008 201.642 445.02 206.491 448.601 210.067C452.182 213.642 457.039 215.651 462.103 215.651H564.463V260.047C564.463 264.517 566.241 268.804 569.407 271.965C572.573 275.125 576.866 276.901 581.343 276.901V276.901C585.82 276.901 590.113 275.125 593.279 271.965C596.445 268.804 598.223 264.517 598.223 260.047V215.651H622.102C625.924 215.651 629.589 214.135 632.292 211.437C634.994 208.739 636.512 205.079 636.512 201.263V201.263C636.512 197.448 634.994 193.788 632.292 191.09C629.59 188.392 625.924 186.876 622.103 186.876Z" fill="url(#paint5_linear_736_3199)"/>
<defs>
<linearGradient id="paint0_linear_736_3199" x1="128.877" y1="22.1888" x2="-84.684" y2="158.586" gradientUnits="userSpaceOnUse">
<stop stop-color="#D360E5"/>
<stop offset="1" stop-color="#3849FB"/>
</linearGradient>
<linearGradient id="paint1_linear_736_3199" x1="571.82" y1="21.1888" x2="358.259" y2="157.586" gradientUnits="userSpaceOnUse">
<stop stop-color="#D360E5"/>
<stop offset="1" stop-color="#3849FB"/>
</linearGradient>
<linearGradient id="paint2_linear_736_3199" x1="350.68" y1="8.89835" x2="140.28" y2="155.243" gradientUnits="userSpaceOnUse">
<stop stop-color="#D360E5"/>
<stop offset="1" stop-color="#3849FB"/>
</linearGradient>
<linearGradient id="paint3_linear_736_3199" x1="314.965" y1="8.05762" x2="315.024" y2="255.114" gradientUnits="userSpaceOnUse">
<stop stop-color="#565A5D"/>
<stop offset="1" stop-color="#2B2B2B"/>
</linearGradient>
<linearGradient id="paint4_linear_736_3199" x1="97.5815" y1="17.7686" x2="-62.5071" y2="162.996" gradientUnits="userSpaceOnUse">
<stop stop-color="#BDBDC1"/>
<stop offset="1" stop-color="#AEAEB5"/>
</linearGradient>
<linearGradient id="paint5_linear_736_3199" x1="539.76" y1="17.7686" x2="379.672" y2="162.996" gradientUnits="userSpaceOnUse">
<stop stop-color="#BDBDC1"/>
<stop offset="1" stop-color="#AEAEB5"/>
</linearGradient>
</defs>
</svg>

</template>