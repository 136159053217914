<template>
    <svg :width="size" :height="size" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">

    <path :fill="color" d="M24.0897 11.2841L10.6898 20.5532C10.2578 20.852 10 21.3438 10 21.869V36.4545C10 37.3381 10.7163 38.0545 11.6 38.0545H38.4C39.2836 38.0545 40 37.3381 40 36.4545V21.869C40 21.3438 39.7422 20.852 39.3102 20.5532L25.9101 11.2841C25.3624 10.9053 24.6374 10.9053 24.0897 11.2841Z"/>
<g filter="url(#filter0_ii_28_286)">
<path :fill="color" d="M10.0136 36.3409C10.0136 37.2873 10.7807 38.0545 11.7271 38.0545H38.6136L11.8474 22.0257C11.0303 21.5363 10.0135 22.1547 10.0135 23.1409L10.0136 36.3409Z"/>
</g>
<path d="M38.3038 37.969H11.7271C10.8281 37.969 10.0992 37.2402 10.0992 36.341L10.0992 23.1409C10.0992 22.214 11.0494 21.6477 11.8034 22.0992L38.3038 37.969Z" stroke="black" stroke-opacity="0.4" stroke-width="0.856755"/>
<g filter="url(#filter1_ii_28_286)">
<path :fill="color" d="M40 36.315V23.1063C40 22.1195 38.934 21.5009 38.0772 21.9904L10.6561 37.6598C10.9012 37.8408 11.1815 37.9544 11.4517 38.0036C11.6815 38.0454 32.1852 38.033 38.2984 38.0282C39.2442 38.0276 40 37.2608 40 36.315Z"/>
</g>
<path d="M39.9144 23.1062V36.3149C39.9144 37.2139 39.1962 37.9419 38.2984 37.9425C35.2418 37.9449 28.5876 37.9491 22.6688 37.9475C19.7094 37.9467 16.934 37.9445 14.884 37.9399C13.859 37.9377 13.0155 37.9347 12.4211 37.9311C12.1239 37.9293 11.8891 37.9271 11.7251 37.9251C11.6431 37.9239 11.5791 37.9227 11.5339 37.9215C11.5113 37.9209 11.4937 37.9203 11.4813 37.9197C11.4699 37.9193 11.4658 37.9189 11.4658 37.9189C11.4658 37.9189 11.4659 37.9189 11.466 37.9189C11.2481 37.8793 11.0231 37.7953 10.8176 37.6661L38.1198 22.0648C38.9194 21.6079 39.9144 22.1853 39.9144 23.1062Z" stroke="black" stroke-opacity="0.4" stroke-width="0.856755"/>
<defs>
<filter id="filter0_ii_28_286" x="10.0135" y="21.8545" width="28.6" height="26.4811" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="10.2811"/>
<feGaussianBlur stdDeviation="5.23233"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_28_286"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="2.52314"/>
<feGaussianBlur stdDeviation="1.30808"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="effect1_innerShadow_28_286" result="effect2_innerShadow_28_286"/>
</filter>
<filter id="filter1_ii_28_286" x="10.6561" y="21.8192" width="29.3439" height="26.4955" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="10.2811"/>
<feGaussianBlur stdDeviation="5.23233"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_28_286"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="2.52314"/>
<feGaussianBlur stdDeviation="1.30808"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="effect1_innerShadow_28_286" result="effect2_innerShadow_28_286"/>
</filter>
</defs>
    </svg>
</template>

<script>
export default {
  props: {
    color: {
        type: String,
        default: 'white'
    },
    size: {
        type: Number,
        default: 48
    }
  }
}
</script>