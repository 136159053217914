<template>
    <Header404page/>
    <Socialpage/>
    <Footer/>
</template>

<script>
// @ is an alias to /src
import Header404page from '@/components/Header404page.vue'
import Footer from '@/components/Footer.vue'
import Socialpage from '@/components/404page.vue'

export default {
  components: {
    Header404page,
    Footer,
    Socialpage
  }
}
</script>
