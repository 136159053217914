<template>
    <section class="sectionpage">
      <div class="container text-center">
        <div class="notfoundpage-title">
            <ICON_404page :size="icon_size"/>
          <h4 class="pt-3">Oops!</h4>
          <p class="pt-3">We can’t find the page <i>{{ path }}</i> you are looking for</p>
          <a href="../" class="pt-3"><button class="backtohome">Back to home</button></a>
       </div>
      </div>
    </section>
</template>
<script>
import ICON_404page from '@/components/icons/404page.vue'
export default {
    components: {
    ICON_404page
  },
    data() {
      return { 
        icon_size: 24,
        path: window.location.pathname
      }
  }
}
</script>
<style scoped>
.sectionpage{
 padding: 150px 40px 60px 40px;
}
.social404page a{
    display: inline-block;
  color: #000;
  padding: 8px;
  margin-right: 10px;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}
.backtohome{
    padding: 10px 32px;
    color: #0880e8;
    transition: .4s;
    border-radius: 50px;
    border: 2px solid #0880e8;
    background: #fff;
}
.backtohome:hover {
    background: #0880e8;
    color: #fff;
}
.notfoundpage-title svg {
    width: 100%;
}
@media only screen and (min-width: 0px) and (max-width: 998px) {
  .sectionpage{
 padding: 40px!important;
}
}
</style>